import React, { useState, useEffect } from "react";
import SellHistoryAnalytic from "../Charts/SellHistoryAnalytic";

export default function SellHistoryWidget({ editMode, id, states, setStates }) {

  const [state, setState] = useState(states.filter(component => component.id == id)[0]);

  useEffect(() => {
    setState(states.filter(component => component.id == id)[0])
  }, [states])

  function handleBlur(event) {
    const test = states
    test.filter(component => component.id == id)[0][event.target.id] = event.target.innerHTML
    setStates(test)
    console.log(test)
  }

  return (
    <div className="sell-history w-full h-full md:p-8 p-4 bg-white dark:bg-dark-white   overflow-hidden rounded-2xl section-shadow">
      <div className="flex flex-col justify-between h-full">
        <div className="content lg:flex justify-between items-center mb-5">
          <div>
            <h1 id="sellertitle" contentEditable={editMode} onBlur={handleBlur} className="text-xl font-bold text-dark-gray dark:text-white tracking-wide mb-1 sm:mb-0">
              {state.sellertitle}
            </h1>
          </div>
          <div className="flex space-x-2  mb-4 sm:mb-0">
            <div className="flex space-x-2 items-center">
              <span className="w-4 h-4 bg-purple block rounded-full"></span>
              <p id="sellerfirst" contentEditable={editMode} onBlur={handleBlur} className="text-sm text-thin-light-gray font-medium">
                {state.sellerfirst}
              </p>
            </div>
            <div className="flex space-x-2 items-center">
              <span className="w-4 h-4 bg-pink block rounded-full"></span>
              <p id="sellersecond" contentEditable={editMode} onBlur={handleBlur} className="text-sm text-thin-light-gray font-medium">
                {state.sellersecond}
              </p>
            </div>
          </div>
          <span className="text-sm text-pink">Current Week</span>
        </div>
        <div>
          <SellHistoryAnalytic />
        </div>
      </div>
    </div>
  );
}
