import React from "react";
import History from "../components/History";

export default function HistoryPage() {
  return (
    <>
      <History />
    </>
  );
}
