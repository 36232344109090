import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editMode: false
};

export const editModeSlice = createSlice({
  name: "editMode",
  initialState,
  reducers: {
    setEditMode: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.editMode = !state.editMode;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setEditMode } = editModeSlice.actions;

export default editModeSlice.reducer;
