import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  designMode: false
};

export const designModeSlice = createSlice({
  name: "designMode",
  initialState,
  reducers: {
    setDesignMode: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.designMode = !state.designMode;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setDesignMode } = designModeSlice.actions;

export default designModeSlice.reducer;
