import React from "react";
import MyWallet from "../components/MyWallet";

export default function MyWalletPage() {
  return (
    <>
      <MyWallet />
    </>
  );
}
