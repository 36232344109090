import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from "react-redux";
import db from "../../data/db.json";
import config from "../../data/config.json";
import { setSave } from '../../store/save';
import Layout from "../Partials/Layout";
import CreateNft from "./CreateNft";
import Hero from "./Hero";
import SellHistoryMarketVisitorAnalytic from "./SellHistoryMarketVisitorAnalytic";
import TopSellerTopBuyerSliderSection from "./TopSellerTopBuyerSliderSection";
import UpdateTable from "./UpdateTable";

export default function Home() {
  const [ids, setIds] = useState(db[0].components.map(component => component.id));
  const designMode = useSelector((state) => state.designMode.designMode)
  const editMode = useSelector((state) => state.editMode.editMode)
  const save = useSelector((state) => state.save.save)
  const [states, setStates] = useState(db[0].components);
  const dispatch = useDispatch()

    const handleDragEnd = (result) => {
        if (!result.destination) return;
        const newIds = [...ids];
        console.log(newIds)
        const [reorderedItem] = newIds.splice(result.source.index, 1);
        newIds.splice(result.destination.index, 0, reorderedItem);
        setIds(newIds);
    };

    useEffect(() => {
        console.log(editMode);
    }, [states, editMode])

    useEffect(() => {
        if (!save) return
        dispatch(setSave())

        for (const key in states) {
            db[0].components.filter(component => component.id == key)[0] = states.filter(component => component.id == key)[0]
        }

        console.log(states)

        const newdb = []
        for (const id of ids) {
            newdb.push(db[0].components.filter(component => component.id == id)[0])
        }
        db[0].components = newdb

        fetch(`${config.api}/edit`, {
            method: 'PATCH',
            headers: {
                "Content-Type": 'application/json',
                Authorization: 'test'
            },
            body: JSON.stringify(db, null, 2)
        })
    }, [save, states, dispatch])

  return (
    <Layout>
      <div className="home-page-wrapper">
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="grid">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="home-page-wrapper">
                {ids.map((id, index) => (
                  <Draggable key={id} draggableId={id} index={index} isDragDisabled={!designMode}>
                    {(provid) => (
                      <div id={id} ref={provid.innerRef} {...provid.draggableProps} {...provid.dragHandleProps}>
                        {id === 'hero' && <Hero id={id} states={states} setStates={setStates} editMode={editMode} className="mb-10" />}
                        {id === 'create-nft' && <CreateNft id={id} states={states} setStates={setStates} editMode={editMode}/>}
                        {id === 'sell-history' && <SellHistoryMarketVisitorAnalytic id={id} states={states} setStates={setStates} editMode={editMode} className="mb-10" />}
                        {id === 'top-seller' && <TopSellerTopBuyerSliderSection id={id} states={states} setStates={setStates} editMode={editMode} className="mb-10" />}
                        {id === 'update-table' && <UpdateTable id={id} states={states} setStates={setStates} editMode={editMode} className="mb-10" />}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>

            </div>
        </Layout>

    );
}
