import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from "react-redux";
import { setSave } from '../../store/save';
import db from "../../data/db.json";
import datas from "../../data/product_data.json";
import Layout from "../Partials/Layout";
import CounterSection from "./CounterSection";
import OverviewSection from "./OverviewSection";

export default function ActiveBids() {
  const [ids, setIds] = useState(db[1].components.map(component => component.id));
  const designMode = useSelector((state) => state.designMode.designMode)
  const editMode = useSelector((state) => state.editMode.editMode)
  const save = useSelector((state) => state.save.save)
  const [states, setStates] = useState(db[1].components)
  const dispatch = useDispatch()

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const newIds = [...ids];
    console.log(newIds)
    const [reorderedItem] = newIds.splice(result.source.index, 1);
    newIds.splice(result.destination.index, 0, reorderedItem);
    setIds(newIds);
};

useEffect(() => {
    console.log(editMode);
}, [states, editMode])

useEffect(() => {
    if (!save) return
    dispatch(setSave())

    for (const key in states) {
        db[0].components.filter(component => component.id == key)[0] = states.filter(component => component.id == key)[0]
    }

    console.log(states)

    const newdb = []
    for (const id of ids) {
        newdb.push(db[0].components.filter(component => component.id == id)[0])
    }
    db[0].components = newdb

    fetch(`${config.api}/edit`, {
        method: 'PATCH',
        headers: {
            "Content-Type": 'application/json',
            Authorization: 'test'
        },
        body: JSON.stringify(db, null, 2)
    })
}, [save, states, dispatch])

  return (
    <Layout>
      <div className="home-page-wrapper">
      <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="grid">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="home-page-wrapper">
                {ids.map((id, index) => (
                  <Draggable key={id} draggableId={id} index={index} isDragDisabled={!designMode}>
                    {(provid) => (
                      <div id={id} ref={provid.innerRef} {...provid.draggableProps} {...provid.dragHandleProps}>
                        {id === 'CounterSection' && <CounterSection id={id} editMode={editMode} states={states} setStates={setStates} className="mb-10" />}
                        {id === 'OverviewSection' && <OverviewSection id={id} editMode={editMode} states={states} setStates={setStates} className="mb-10" />}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Layout>
  );
}
