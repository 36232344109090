import React from "react";
import MarketPlace from "../components/MarketPlace";

export default function MarketPlacePage() {
  return (
    <>
      <MarketPlace />
    </>
  );
}
