import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function CreateNft({ id, states, setStates, editMode, className }) {

  const [state, setState] = useState(states.filter(component => component.id == id)[0]);

  useEffect(() => {
    setState(states.filter(component => component.id == id)[0])
  }, [states])

  function handleBlur(event) {
    const test = states
    test.filter(component => component.id == id)[0][event.target.id] = event.target.innerHTML
    setStates(test)
    console.log(test)
  }

  return (
    <div
      className={`create-nft w-full lg:h-[140px] shadow lg:flex rounded-lg justify-between items-center  md:p-9 p-4 bg-white dark:bg-dark-white   border-b dark:border-[#5356fb29] -2 border-pink mb-10 ${className || ""
        }`}
    >
      <div className="lg:w-8/12 w-full mb-8 lg:mb-0">
        <h1 id="title" contentEditable={editMode} onBlur={handleBlur} className="text-2xl text-dark-gray dark:text-white font-bold mb-2">
          {state.title}
        </h1>
        <p id="description" contentEditable={editMode} onBlur={handleBlur} className="text-base text-thin-light-gray tracking-wide">
          {state.description}
        </p>
      </div>
      <div className="flex-1 flex lg:justify-end">
        <div className="flex items-center space-x-5">
          <Link
            id="button" contentEditable={editMode} onBlur={handleBlur}
            to="/upload-product"
            className="w-40 h-11 flex justify-center items-center btn-gradient text-base rounded-full text-white"
          >
            {state.button}
          </Link>
          <Link to="/market-place" className="text-dark-gray dark:text-white text-base">
            <span id="link" contentEditable={editMode} onBlur={handleBlur} className=" border-b dark:border-[#5356fb29]  border-dark-gray font-medium">
              {state.link}
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}
