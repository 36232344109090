import React from "react";
import Settings from "../components/Settings";

export default function SettingsPage() {
  return (
    <>
      <Settings />
    </>
  );
}
