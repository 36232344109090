import React from "react";
import ShopDetails from "../components/ShopDetails";

export default function ShopDetailsPage() {
  return (
    <>
      <ShopDetails />
    </>
  );
}
