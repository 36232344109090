import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  save: false
};

export const saveSlice = createSlice({
  name: "save",
  initialState,
  reducers: {
    setSave: (state) => {
      // eslint-disable-next-line no-param-reassign
      state.save = !state.save;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSave } = saveSlice.actions;

export default saveSlice.reducer;
