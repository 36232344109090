import { configureStore } from "@reduxjs/toolkit";
import designModeSlice from './designMode';
import drawerReducer from "./drawer";
import editModeSlice from './editMode';
import saveSlice from './save';

export default configureStore({
  reducer: {
    drawer: drawerReducer,
    designMode: designModeSlice,
    editMode: editModeSlice,
    save: saveSlice
  },
});
