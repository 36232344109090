import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import heroBg from "../../assets/images/hero-bg.svg";
import heroUser from "../../assets/images/hero-user.png";
import slider1Img from "../../assets/images/slider-1.jpg";
import slider2Img from "../../assets/images/slider-2.jpg";
import slider3Img from "../../assets/images/slider-3.jpg";
import config from "../../data/config.json";
import CountDown from "../Helpers/CountDown";
import SliderCom from "../Helpers/SliderCom";

export default function Hero({ id, states, setStates, editMode, className }) {
  const settings = {
    autoplay: true,
    dots: true,
    arrows: false,
    infinite: true,
    swipe: true,
  };
  const [addFavorite, setValue] = useState(false);
  const [state, setState] = useState(states.filter(component => component.id == id)[0]);
  const [profileImag, setProfileImg] = useState(heroUser);
  const [slider1, setSlider1] = useState(slider1Img);
  const [slider2, setSlider2] = useState(slider2Img);
  const [slider3, setSlider3] = useState(slider3Img);

  useEffect(() => {
    setState(states.filter(component => component.id == id)[0])
  }, [states])

  const profileImgInput = useRef(null);
  const slider1Input = useRef(null);
  const slider2Input = useRef(null);
  const slider3Input = useRef(null);
  const browseImage = (imageInput) => {
    if (!editMode) return
    imageInput.current.click();
  };
  const ImageChangHandler = (e, setImage) => {
    if (e.target.value !== "") {
      const imgReader = new FileReader();
      imgReader.onload = (event) => {
        const formData = new FormData();
        formData.append('image', e.target.files[0], e.target.id)
        setImage(event.target.result);
        fetch(`${config.api}/upload`, {
          method: 'POST',
          body: formData
        })
      };
      imgReader.readAsDataURL(e.target.files[0]);
    }
  };

  function handleBlur(event) {
    const test = states
    test.filter(component => component.id == id)[0][event.target.id] = event.target.innerHTML
    setStates(test)
    console.log(test)
  }

  const favoriteHandler = () => {
    if (!addFavorite) {
      setValue(true);
      toast.success("Added to Favorite List");
    } else {
      setValue(false);
      toast.warn("Remove to Favorite List");
    }
  };

  return (
    <div
      className={`w-full lg:h-[444px] h-full lg:flex lg:p-8 p-4 justify-between items-center lg:space-x-28 rounded-2xl overflow-hidden ${className || ""
        }`}
      style={{
        background: `url(${heroBg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="flex-1 h-[330px] lg:h-full flex flex-col justify-between mb-5 lg:mb-0">
        {/* heading */}
        <div>
          <h1 id="title" contentEditable={editMode} onBlur={handleBlur} className="lg:text-2xl text-xl font-medium text-white tracking-wide">
            {state.title}
          </h1>
          <span id="subtitle" contentEditable={editMode} onBlur={handleBlur} className="text-[18px] font-thin tracking-wide text-white">
            {state.subtitle}
          </span>
        </div>
        {/* user */}
        <div className="flex items-center space-x-3">
          <div className="w-14 h-14 flex justify-center items-center rounded-full overflow-hidden">
            <img src={profileImag} alt="" onDoubleClick={() => browseImage(profileImgInput)} />
            <input
              id="hero-user.png"
              ref={profileImgInput}
              onChange={(e) => ImageChangHandler(e, setProfileImg)}
              type="file"
              className="hidden"
            />
          </div>
          <div>
            <p id="author" contentEditable={editMode} onBlur={handleBlur} className="text-xl tracking-wide font-bold antise text-white">
              {state.author}
            </p>
            <p id="username" contentEditable={editMode} onBlur={handleBlur} className="text-sm tracking-wide text-white">{state.username}</p>
          </div>
        </div>
        {/* countdown */}
        <div className="w-full h-32 flex justify-evenly items-center sm:p-6 p-1 rounded-2xl border border-white-opacity">
          <div className="flex flex-col justify-between">
            <p id="field.title" contentEditable={editMode} onBlur={handleBlur} className="text-base text-white tracking-wide">{state["field.title"]}</p>
            <p id="field.reward" contentEditable={editMode} onBlur={handleBlur} className="lg:text-2xl text-lg font-bold tracking-wide text-white">
              {state["field.reward"]}
            </p>
            <p id="field.subreward" contentEditable={editMode} onBlur={handleBlur} className="text-base text-white tracking-wide">{state["field.subreward"]}</p>
          </div>
          <div className="w-[1px] h-full bg-white-opacity"></div>
          <div className="flex flex-col justify-between">
            <p id="field.countdown" contentEditable={editMode} onBlur={handleBlur} className="text-base text-white tracking-wide">{state["field.countdown"]}</p>
            <p className="lg:text-2xl text-lg font-bold tracking-wide text-white">
              <CountDown lastDate={state["field.date"] ? state["field.date"] : "2023-07-07 4:00:00"} />
            </p>
            <div className="text-base text-white tracking-wide flex gap-[23px]">
              <span>Hrs</span>
              <span>Min</span>
              <span>Sec</span>
            </div>
          </div>
        </div>
        {/* action */}
        <div className="flex lg:space-x-3 space-x-1 items-center">
          <button
            onClick={favoriteHandler}
            type="button"
            className={`w-[52px] h-[52px] rounded-full bg-white    flex justify-center items-center ${addFavorite ? "text-pink" : "  text-dark-gray"
              }`}
          >
            <svg
              width="26"
              height="24"
              viewBox="0 0 26 24"
              fill="none"
              className="fill-current"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7.23431 0C8.44147 0.0182463 9.6223 0.345711 10.6576 0.949325C11.6928 1.55294 12.5458 2.41133 13.1305 3.4378C13.7151 2.41133 14.5681 1.55294 15.6034 0.949325C16.6386 0.345711 17.8195 0.0182463 19.0266 0C20.951 0.0812475 22.764 0.899302 24.0697 2.27544C25.3753 3.65158 26.0674 5.47395 25.9948 7.3444C25.9948 14.4023 14.2497 22.5541 13.7501 22.8999L13.1305 23.326L12.5108 22.8999C12.0113 22.5562 0.266127 14.4023 0.266127 7.3444C0.193496 5.47395 0.885607 3.65158 2.19126 2.27544C3.4969 0.899302 5.30996 0.0812475 7.23431 0Z" />
            </svg>
          </button>
          <Link
            id="button" contentEditable={editMode} onBlur={handleBlur}
            to="/active-bids"
            className=" btn-shine  w-[116px] h-[46px] text-white rounded-full text-base bg-pink flex justify-center items-center"
          >
            {state.button}
          </Link>
          <Link
            to="/market-place"
            className="text-white text-base sm:block hidden"
          >
            <span id="link" contentEditable={editMode} onBlur={handleBlur} className=" border-b dark:border-[#5356fb29]  border-white">
              {" "}
              {state.link}
            </span>
          </Link>
        </div>
      </div>
      <div className="hero-slider relative 2xl:w-[600px] xl:w-[500px] lg:w-[420px] w-full mb-2 lg:mb-0 ">
        <div className="w-full">
          <SliderCom settings={settings}>
            <div className="item w-full h-full bg-white dark:bg-dark-white   rounded-2xl overflow-hidden">
              <img src={slider1} alt="slider" className="w-full h-full" onDoubleClick={() => browseImage(slider1Input)} />
              <input
                ref={slider1Input}
                id="slider-1.jpg"
                onChange={(e) => ImageChangHandler(e, setSlider1)}
                type="file"
                className="hidden"
              />
            </div>
            <div className="item w-full h-full bg-white dark:bg-dark-white   rounded-2xl overflow-hidden">
              <img src={slider2} alt="slider" className="w-full h-full" onDoubleClick={() => browseImage(slider2Input)} />
              <input
                ref={slider2Input}
                id="slider-2.jpg"
                onChange={(e) => ImageChangHandler(e, setSlider2)}
                type="file"
                className="hidden"
              />
            </div>
            <div className="item w-full h-full bg-white dark:bg-dark-white   rounded-2xl overflow-hidden">
              <img src={slider3} alt="slider" className="w-full h-full" onDoubleClick={() => browseImage(slider3Input)} />
              <input
                ref={slider3Input}
                id="slider-3.jpg"
                onChange={(e) => ImageChangHandler(e, setSlider3)}
                type="file"
                className="hidden"
              />
            </div>
          </SliderCom>
        </div>
      </div>
    </div>
  );
}
