import React, { useEffect } from "react";
import config from "../data/config.json";

function LoginPage() {

  useEffect(() => {
    window.location.replace(`${config.authUrl}`)
  }, [])

  return (
    <>
    </>
  );
}

export default LoginPage;
